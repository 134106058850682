import React, { Component } from "react"
import styles from "./footer.module.css"
import { Link } from "gatsby"

class Footer extends Component {
  render() {
    return (
      <div className={styles.footer}>
        <ul className={styles.footerNavigation}>
          <li className={styles.linkListItem}>
            <Link to="/">Home</Link>
          </li>
          <li className={styles.linkListItem}>
            <Link to="/about">Über Menon</Link>
          </li>
          <li className={styles.linkListItem}>
            <Link to="/team">Team</Link>
          </li>
          <li className={styles.linkListItem}>
            <Link to="/contact">Kontakt</Link>
          </li>
          <li className={styles.linkListItem}>
            <Link to="/impressum">Impressum</Link>
          </li>
          <li className={styles.linkListItem}>
          ©2019
          </li>
        </ul>
      </div>
    )
  }
}

export default Footer
