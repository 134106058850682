import React, { Component } from "react"
import { Link } from "gatsby"
import styles from "./header.module.css"
import Button from "../buttons/button"
import Logo from "../../img/logo_blue.svg"

class Header extends Component {
  render() {
    return (
      <header>
        <div className={styles.headerContent}>
          <nav>
            <Link to="/" className={styles.logo}>
              <Logo className={styles.logoIcon} />
              <h1 className={styles.title}>Menon</h1>
            </Link>

            <Link to="/blog">Blog</Link>
            <Link to="/contact">Kontakt</Link>
            <Link to="/team">Team</Link>
            <Button buttonText={"Mehr erfahren"} link="/about"/>
          </nav>
        </div>
      </header>
    )
  }
}

export default Header
