import React, { Component } from "react"
import styles from "./button.module.css"
import { Link } from "gatsby"


class Button extends Component {
  render() {
    if (this.props.link !== "" && this.props.link !== null && this.props.link !== undefined) {
      return <Link to={this.props.link} className={styles.raisedButton}>{this.props.buttonText}</Link>
    }
    return <button type={this.props.type} className={styles.raisedButton}>{this.props.buttonText}</button>
  }
}

export default Button
