import React, { Component } from "react"

import "../../styles/global.css"

import Header from "../header/header"
import Footer from "../footer/footer"

class Layout extends Component {
  render() {
    return (
      <div className="layout">
        <Header />
        <div className="main">
          {this.props.children}
        </div>
        <Footer />

      </div>
    )
  }
}

export default Layout
