// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-post-post-js": () => import("C:\\repos\\menon website\\menon_website\\src\\components\\post\\post.js" /* webpackChunkName: "component---src-components-post-post-js" */),
  "component---src-pages-about-js": () => import("C:\\repos\\menon website\\menon_website\\src\\pages\\about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("C:\\repos\\menon website\\menon_website\\src\\pages\\blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("C:\\repos\\menon website\\menon_website\\src\\pages\\contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-impressum-js": () => import("C:\\repos\\menon website\\menon_website\\src\\pages\\impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("C:\\repos\\menon website\\menon_website\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-team-js": () => import("C:\\repos\\menon website\\menon_website\\src\\pages\\team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "C:\\repos\\menon website\\menon_website\\.cache\\data.json")

