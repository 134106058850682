module.exports = [{
      plugin: require('C:/repos/menon website/menon_website/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"C:\\repos\\menon website\\menon_website\\src\\components\\layout\\layout.js"},
    },{
      plugin: require('C:/repos/menon website/menon_website/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":970},
    },{
      plugin: require('C:/repos/menon website/menon_website/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
